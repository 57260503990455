<template>
  <div
    class="screen-header"
    v-bind:class="{ 'full-screen': configStore.fullScreen }"
  >
    <button
      @click="$router.go(-1)"
      class="back-button"
      :aria-label="backButtonTitle"
      :title="backButtonTitle"
    >
      <span class="bt-icon bt-icon-arrow-left-1"></span>
    </button>
    <close-button
      class="close-button"
      v-if="!configStore.config.embedded"
    ></close-button>
    <a
      class="external-link"
      target="_blank"
      :href="externalLink"
      v-if="externalLink"
      title="Open in New Tab"
    >
      <button class="external-link-button">
        <span class="bt-icon bt-icon-move"></span>
      </button>
    </a>
    <button
      class="download-link-button"
      v-if="downloadLink"
      @click="download(downloadLink)"
    >
      <span class="bt-icon bt-icon-download"></span>
    </button>
    <button
      class="nav-cta end-chat-button"
      v-if="endChat && showEndChatForCurrentOrg"
      @click="goToEndChat()"
    >
      End Chat
    </button>
    <button
      class="nav-cta chat-with-human-button"
      v-if="
        chatStore.state.chatSource === 'bot' &&
        !partnerStore.config.unavailable &&
        $route.name == 'ChatScreen'
      "
      @click="goToChatWithHuman()"
      title="Route to live person"
    >
      <span class="bt-icon bt-icon-user"></span>
    </button>
  </div>
</template>

<script>
import CloseButton from '@/components/CloseButton'
import { find, startCase } from 'lodash'
import { base64ToFile, blobToFile, saveDivAsImage } from '../utils/helpers'
import { chatStore } from '../stores/chatStore'

export default {
  name: 'ScreenHeader',
  components: { CloseButton },
  inject: ['configStore', 'partnerStore', 'chatStore'],
  props: {
    externalLink: {
      type: String,
      requred: false,
    },
    endChat: {
      type: Boolean,
      required: false,
    },
    downloadLink: {
      type: String,
      required: false,
    },
  },
  data: function () {
    return {}
  },
  methods: {
    toDataURL(url) {},
    download(downloadLink) {
      return fetch(downloadLink)
        .then(response => {
          response.blob().then(function (blob) {
            let url = URL.createObjectURL(blob)
            let a = document.createElement('a')
            let downloadLinkParts = downloadLink.split('/')
            a.href = url
            a.download = downloadLinkParts[downloadLinkParts.length - 1]
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
          })
        })
        .then(blob => {
          return
        })
    },
    goToEndChat() {
      this.$router.push({ name: 'EndChat' })
    },
    async goToChatWithHuman() {
      const messageList = document.getElementById('message-list')
      messageList.style['padding-bottom'] = '60px'
      messageList.style['bottom'] = '0px'
      const chatFile = saveDivAsImage('message-list').then(dataUrl => {
        chatStore.storeBotChatImage(dataUrl)
      })
      messageList.style['padding-bottom'] = '0px'
      messageList.style['bottom'] = '61px'
      this.$router.push({ name: 'ChatWithHuman' })
    },
  },
  computed: {
    backButtonTitle() {
      let route = find(this.$router.options.routes, route => {
        return route.path === this.$router.options.history.state.back
      })
      if (route) {
        return 'Back to Messenger ' + startCase(route.name)
      } else {
        return 'Back'
      }
    },
    showEndChatForCurrentOrg() {
      return this.partnerStore.config.partnerTeam?.split('-')[0] !== 'LWH'
    },
  },
  mounted() {},
}
</script>

<style scoped lang="scss">
@import '../styles/variables';
.download-link-button,
.external-link-button,
.back-button {
  background: none;
  outline: none;
  display: block;
  color: #596168;
  font-size: 14px;
  border-radius: 6px;
  width: 48px;
  height: 48px;
  line-height: 48px;
  font-weight: bold;
  border: 1px solid #fff;
  float: left;
  transition: 250ms border-color ease;
  text-align: center;
  cursor: pointer;
  margin-top: 7px;
}

.download-link-button:focus-visible,
.external-link-button:focus-visible,
.back-button:focus-visible {
  border: 1px solid #596168;
}

.bt-icon-arrow-left-1 {
  font-weight: bold;
}

.download-link-button,
.external-link-button {
  float: right;
  font-size: 18px;
  margin-top: 7px;
  margin-right: -14px;
}

.download-link-button {
  margin-top: 9px;
}

.download-link-button,
.external-link-button:hover {
  color: #505050;
}

.back-button:hover {
  background-color: #f7f8fa;
}

.back-button:active {
  border-color: #acacac;
}

.screen-header {
  position: relative;
  height: $screen-header-height;
  line-height: $screen-header-height;
  color: #838383;
  background-color: #fff;
  border-bottom: 1px solid #ebebeb;
  padding-left: 6px;
  padding-right: 24px;
}

.end-chat-button {
  float: right;
  font-size: 14px;
  margin-top: 18px;
  color: #596168;
  background: transparent;
}

.chat-with-human-button {
  float: right;
  font-size: 14px;
  margin-top: 12px;
  margin-right: 6px;
  height: 36px;
  width: 36px;
  border: 1px solid gray;
  background: transparent;
}

.chat-with-human-button:focus-visible {
  border: 1px solid #596168;
}

.end-chat-button:hover {
  color: #505050;
}
.end-chat-button:focus {
  text-decoration: underline;
}

.close-button {
  float: right;
  margin-top: 16px;
  margin-left: 10px;
  display: none;
  color: #596168;
}

@media only screen and (max-width: 419px) {
  .close-button {
    display: block;
  }
}

@media only screen and (min-width: 421px) {
  .close-button {
    display: block;
  }
}

.full-screen {
  .close-button {
    display: block;
  }
}
</style>
