<template>
  <div class="chat-container">
    <div
      class="loader"
      v-if="
        (!chatService.client || !chatService.client.mucJoined) &&
        chatStore.state.chatSource != 'bot'
      "
    >
      <chat-loader class="loader"></chat-loader>
    </div>
    <transition :name="fadeInTransition">
      <div
        class="chat"
        v-if="
          chatStore.state.chatSource == 'bot' ||
          (chatService.client && chatService.client.mucJoined)
        "
      >
        <div
          class="message-list"
          id="message-list"
          ref="message-list"
          v-bind:class="{
            resolved:
              chatStore.state.chatSource != 'bot' && !issueStore.issue.active,
          }"
          aria-live="polite"
        >
          <transition-group name="fadeIn">
            <chat-message
              v-for="(message, index) in chatStore.state.messages"
              :key="index"
              :message="message"
              :previous-message="chatStore.state.messages[index - 1]"
            ></chat-message>
          </transition-group>
          <typing-indicator
            v-if="chatStore.state.botTyping"
            :participant="chatStore.state.botTyping"
          ></typing-indicator>
          <typing-indicator
            v-if="chatStore.state.userTyping"
            :participant="chatStore.state.userTyping"
          ></typing-indicator>
          <transition name="fadeIn">
            <user-feedback
              id="userFeedback"
              v-show="
                chatStore.state.chatSource != 'bot' && !issueStore.issue.active
              "
            ></user-feedback>
          </transition>
        </div>
        <div
          v-if="chatStore.state.chatSource == 'bot' || issueStore.issue.active"
          class="message-box"
        >
          <message-box></message-box>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import ChatMessage from '@/components/ChatMessage'
import TypingIndicator from '@/components/TypingIndicator'
import UserFeedback from '@/components/UserFeedback'
import MessageBox from '@/components/MessageBox'
import { enterComm } from '@/api/commApi'
import ChatLoader from '@/components/ChatLoader'
import chatService from '../services/chatService'
import globals from '../globals'
import { createIssue, getIssue } from '@/api/issueApi'

export default {
  name: 'Chat',
  inject: ['chatStore', 'memberStore', 'issueStore', 'notificationStore'],
  components: {
    ChatMessage,
    TypingIndicator,
    UserFeedback,
    MessageBox,
    ChatLoader,
  },
  data: function () {
    return {
      chatService,
      fadeInTransition: chatService.client ? 'fadeIn' : 'fadeInDelay',
      issueStatusInterval: null,
    }
  },
  methods: {
    async startChat() {
      let commData
      let commId = this.issueStore.issue.comm_id
      let membersUsersId = this.memberStore.member.members_users_id
      try {
        commData = await enterComm(commId, membersUsersId)
        Object.values(commData.participants).forEach(participant => {
          this.chatStore.addParticipant(participant)
        })
        this.chatService.createClient(this.issueStore.issue.xmppData, commData)
      } catch (e) {
        // todo: log and handle errors
        console.log('e!', e)
      }
    },
    createIssue() {
      return createIssue()
        .then(issue => {
          this.issueStore.setIssue(issue)
        })
        .catch(error => {
          this.$router.go(-1)
          this.notificationStore.addNotification({
            title: 'Error',
            message: "We've encountered an error. Please try again",
            level: 'error',
          })
        })
        .finally(() => {
          // this.submittingMember = false;
        })
    },
    clearUnreadMessages() {
      window.setTimeout(() => {
        this.chatStore.clearUnreadMessageCount()
        var clearUnreadMessageCountMessage = JSON.stringify({
          type: 'clearUnreadMessageCount',
          data: null,
        })
        window.parent.postMessage(
          clearUnreadMessageCountMessage,
          globals.parentOrigin,
        )
      }, 500)
    },
    setupIssueStatusInterval() {
      this.issueStatusInterval = window.setInterval(async () => {
        try {
          if (!this.issueStore.issue.id) return
          const issue = await getIssue(this.issueStore.issue.id)
          if (issue) {
            this.issueStore.setIssue(issue)
          } else {
            this.issueStore.setIssue({
              status: 'Deleted',
              timeResolved: new Date(),
            })
          }
          this.handleIssueStatus()
        } catch (error) {
          this.$router.go('HomeScreen')
          console.log('error: ', error)
        }
      }, 7500)
    },
    handleIssueStatus() {
      if (
        this.issueStore.issue.status.match(/Closed|Resolved|Canceled|Deleted/i)
      ) {
        window.clearInterval(this.issueStatusInterval)
        const userFeedbackEl = document.getElementById('userFeedback')
        setTimeout(() => {
          userFeedbackEl.scrollIntoView()
        }, 250)
      }
    },
  },
  computed: {},
  watch: {
    'chatStore.state.chatSource': async function (newVal, oldVal) {
      console.log('newVal: ', newVal)
      console.log('oldVal: ', oldVal)
      if (oldVal == 'bot' && newVal != 'bot') {
        await this.createIssue()
        await this.startChat()
        this.setupIssueStatusInterval()
      }
    },
  },
  async mounted() {
    if (this.chatStore.state.chatSource != 'bot') {
      if (!this.issueStore.issue?.status?.match(/Closed|Canceled|Deleted/i)) {
        // start or re-start chat
        if (!this.issueStore.issue.id) await this.createIssue()
        if (!this.chatService.client) await this.startChat()
        this.setupIssueStatusInterval()
      } else {
        // show view only of inactive chat
      }
    }
    this.clearUnreadMessages()
  },
  unmounted() {
    if (this.issueStatusInterval) window.clearInterval(this.issueStatusInterval)
  },
}
</script>

<style scope lang="scss">
.chat {
  position: absolute;
  bottom: 0px;
  top: 62px;
  left: 0px;
  right: 0px;
  background: #fff;
}

.message-list {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 61px;
  overflow-y: scroll;
  scroll-behavior: smooth;
  padding-left: 24px;
  padding-right: 24px;

  &.resolved {
    bottom: 6px;
  }
}

.loader {
  margin-top: 24px;
  text-align: center;
}

.message-box {
  margin-left: 24px;
  margin-right: 24px;
}
</style>
