<template>
  <div class="feedback-wrapper">
    <div class="chat-ended-message" :class="{'hide-feedback': hideFeedback}">
      This chat ended
      <Timestamp :datetime="timestamp" :include-prefix="true"></Timestamp>
    </div>
    <div class="feedback" v-if="!hideFeedback">
      <div
        class="feedback-header"
        :style="{
          'background-color': themeStore.theme.primaryColor,
        }"
      >
        <div class="avatar-bg">
          <img
            class="avatar"
            :src="
              partnerStore.config.partnerAvatar ||
              partnerStore.config.authenticationLogo
            "
            alt="Partner Avatar"
            v-if="
              partnerStore.config.partnerAvatar ||
              partnerStore.config.authenticationLogo
            "
          />
        </div>
      </div>
      <h3 class="feedback-title" v-if="!feedbackSubmitted">
        Rate Your Experience
      </h3>
      <transition name="fadeIn">
        <h3 class="feedback-title" v-if="feedbackSubmitted">
          Feedback Submitted
          <span class="bt-icon bt-icon-check-circle"></span>
        </h3>
      </transition>
      <form
        action=""
        @submit.prevent="submitFeedback"
        class="feedback-form"
        novalidate
      >
        <div class="stars" v-bind:class="{ 'read-only': feedbackSubmitted }">
          <button
            v-for="num in 5"
            :key="num"
            class="star"
            @mouseenter="setHover(num)"
            @mouseleave="unsetHover(num)"
            @click="selectRating(num)"
            type="button"
            :disabled="feedbackSubmitted"
          >
            <div class="star-wrapper">
              <div class="bt-icon bt-icon-star"></div>
              <div
                class="bt-icon bt-icon-star-filled"
                v-bind:class="{ show: filled[num] || selected[num] }"
              ></div>
            </div>
          </button>
        </div>
        <textarea
          v-model="feedbackText"
          name="feedback-textarea"
          class="feedback-textarea"
          id="feedback-textarea"
          :disabled="feedbackSubmitted"
          placeholder="Share any additional feeback"
        ></textarea>
        <PrimaryCTA
          v-show="!feedbackSubmitted"
          class="primary-cta"
          :disabled="ratingValue < 1"
          :submitting="submittingFeedback"
          text="Send"
        ></PrimaryCTA>
      </form>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { rateIssue } from '@/api/issueApi'
import Timestamp from '@/components/Timestamp'
import PrimaryCTA from './PrimaryCTA'

export default {
  name: 'UserFeedback',
  components: { Timestamp, PrimaryCTA },
  inject: [
    'chatStore',
    'partnerStore',
    'memberStore',
    'issueStore',
    'themeStore',
  ],
  data: function () {
    return {
      filled: {},
      selected: {},
      feedbackText: '',
      ratingValue: 0,
      submittingFeedback: false,
      feedbackSubmitted: false,
      primaryColor: this.themeStore.theme.primaryColor,
    }
  },
  methods: {
    setHover(stars) {
      _.times(stars, index => {
        this.filled[index + 1] = true
      })
    },
    unsetHover(stars) {
      _.times(stars, index => {
        this.filled[index + 1] = false
      })
    },
    selectRating(stars) {
      this.selected = {}
      _.times(stars, index => {
        this.selected[index + 1] = true
      })
      this.ratingValue = stars
    },
    submitFeedback() {
      this.submittingFeedback = true
      let issueId = this.issueStore.issue.id
      rateIssue(issueId, this.ratingValue, this.feedbackText)
        .then(response => {
          this.issueStore.addFeedback({
            ratingValue: this.ratingValue,
            feedbackText: this.feedbackText,
          })
          this.issueStore.setIssue({ status: 'Closed' })
          this.feedbackSubmitted = true
          this.$router.push({
            name: 'HomeScreen',
          })
        })
        .catch(error => {
          console.log('error! ', error)
        })
        .finally(() => {
          this.submittingFeedback = false
        })
    },
  },
  computed: {
    hideFeedback() {
      return this.issueStore.issue.status === 'Canceled'
    },
    timestamp() {
      let timestamp =
        this.issueStore?.issue?.timeResolved || this.issueStore?.issue?.updated
      if (!timestamp) return ''
      return timestamp
    },
  },
  mounted() {
    if (this.issueStore.issue.feedback) {
      this.feedbackText = this.issueStore.issue.feedback.feedbackText
      this.ratingValue = this.issueStore.issue.feedback.ratingValue
      this.feedbackSubmitted = true
      _.times(this.ratingValue, index => {
        this.selected[index + 1] = true
        this.filled[index + 1] = true
      })
    } else {
      let feedbackInput = document.getElementById('feedback-textarea')
      feedbackInput && feedbackInput.focus()
    }
  },
}
</script>

<style scoped lang="scss">
.feedback {
  width: 74%;
  margin: 18px auto 24px auto;
  border-radius: 6px;
  background-color: #f7f8fa;
  overflow: hidden;
  text-align: center;
}

.chat-ended-message {
  text-align: center;
  font-size: 14px;
  color: #596168;
  margin-top: 18px;
}

.hide-feedback {
  margin-bottom: 24px;
}

.feedback-header {
  height: 36px;
  text-align: center;
  margin-bottom: 36px;
}

.avatar-bg {
  margin-top: 10px;
  display: inline-block;
  background: white;
  width: 54px;
  height: 54px;
  border-radius: 50%;
}

.avatar {
  width: 46px;
  height: 46px;
  border-radius: 50%;
  margin-top: 4px;
}

.feedback-title {
  text-align: center;
  font-weight: 500;
}

.stars {
  display: flex;
  justify-content: space-between;
  width: 60%;
  margin: auto;
  margin-top: 30px;
  height: 30px;
}

.star {
  font-size: 20px;
  width: 24px;
  cursor: pointer;
  background: transparent;

  &:focus-visible {
    outline-style: double;
    outline-color: v-bind(primaryColor);
  }
  position: relative;
}

.star:hover {
  // transition: all 250ms linear !important;
  transition: font-family 0.2s ease;
}

.feedback-textarea {
  padding: 8px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  margin: 4px auto 18px auto;

  resize: none;
  line-height: 1.4;
  // font-size: 14px;
  // width: 85%;
  font-size: 16px;
  width: 97%;
  transform: scale(0.875);
  transform-origin: center;
  &::placeholder {
    color: #6b737b;
  }
}

.read-only {
  pointer-events: none;
}

.bt-icon-star,
.bt-icon-star-filled {
  position: absolute;
  left: 2px;
  top: 5px;
  color: v-bind(primaryColor);
}

.bt-icon-star-filled {
  opacity: 0;
}
.bt-icon-star-filled.show {
  opacity: 1;
  transition: 250ms opacity ease;
}

.primary-cta {
  width: 85%;
  padding: 6px;
  margin: 0px auto 18px auto;
}

.bt-icon-check-circle {
  color: rgb(39, 196, 39);
  vertical-align: text-bottom;
  font-size: 21px;
}
</style>
