<template>
  <div
    class="message"
    v-bind:class="{ 'align-right': fromUser, 'align-left': !fromUser }"
    v-if="!message.hideMessage"
    ref="message"
  >
    <div class="avatar-wrapper" v-if="!fromUser">
      <img
        class="avatar"
        :src="message.avatar"
        v-if="message.resource != 'ovation-bot' && showFromName"
      />
      <img
        class="avatar"
        src="@/assets/bot-avatar.png"
        v-if="message.resource == 'ovation-bot' && showFromName"
      />
    </div>
    <div class="from-name" v-if="showFromName">
      {{ fromDisplayName }}
      <Timestamp :datetime="message.timestamp"></Timestamp>
    </div>
    <div class="message-text" v-if="message.message">
      <span v-html="parsedMessage"></span>
      <div v-if="message.triggers?.escalateCase">
        <a href="" @click.prevent="goToChatWithHuman"
        to="/chat/chat-with-human"
          >Chat with a representative</a
        >
      </div>
    </div>
    <div class="message-attachments">
      <div
        class="image-wrapper"
        v-if="message.hasImage"
        @click="goToImagePreview(message.attachmentUrl)"
      >
        <img :src="message.attachmentPreview" class="image" />
      </div>
      <user-identification
        style="right: 5%"
        v-if="message.showUserIdentification && !memberStore.member.members_id"
      ></user-identification>
      <k-b-preview
        v-if="message.hasKb"
        :id="message.kbId"
        :title="message.kbTitle"
        :preview="message.kbPreview"
      ></k-b-preview>
      <k-b-preview-list
        v-if="message.kbList && message.kbList.length > 0"
        :list="message.kbList"
      ></k-b-preview-list>
      <div v-if="message.ratingActions">
        <span v-for="(action, index) in message.ratingActions" :key="index">
          <rating-button
            :label="action.lbl"
            @click="onRatingClick(action, message, $event)"
            :color="themeStore.theme.primaryHoverColor"
            :selected="action.selected"
          ></rating-button>
        </span>
      </div>
      <div v-if="message.actions">
        <span v-for="(action, index) in message.actions" :key="index">
          <action-button
            :label="action.lbl"
            @click="onActionClick(action, message, $event)"
            :color="themeStore.theme.primaryHoverColor"
            :selected="action.selected"
            :disabled="message.botActionSelected"
          ></action-button>
        </span>
      </div>
      <div
        class="file-attachment"
        v-if="message.hasAttachment && !message.hasImage"
      >
        <a
          :href="message.attachmentUrl"
          target="_blank"
          class="file-attachment-link"
          download
        >
          <div class="file-wrapper">
            <span :class="iconClasses"></span>
            <span class="file-name-wrapper">
              <div class="file-name">{{ message.attachmentName }}</div>
              <div class="file-size">{{ message.attachmentSize }}</div>
            </span>
          </div>
        </a>
      </div>
    </div>
    <div v-if="message.lastBotMessage" class="transfer-message">
      Transferring the conversation. A representative will be with your shortly
    </div>
  </div>
</template>

<script>
import ActionButton from '@/components/ActionButton'
import RatingButton from '@/components/RatingButton'
import chatService from '@/services/chatService'
import CryptoJS from 'crypto-js'
import Timestamp from '@/components/Timestamp'
import UserIdentification from '@/components/UserIdentification'
import KBPreview from './KBPreview'
import KBPreviewList from './KBPreviewList.vue'
import { saveDivAsImage } from '../utils/helpers'
import { chatStore } from '../stores/chatStore'
import { memberStore } from '../stores/memberStore'

import { parseMarkup } from '../utils/helpers'

export default {
  name: 'ChatMessage',
  inject: ['memberStore', 'themeStore'],
  components: {
    ActionButton,
    RatingButton,
    Timestamp,
    KBPreview,
    KBPreviewList,
    UserIdentification,
  },
  props: {
    message: {
      type: Object,
      required: true,
    },
    previousMessage: {
      type: Object,
      default: () => {
        return {}
      },
      required: false,
    },
  },
  data: function () {
    return {}
  },
  methods: {
    async goToChatWithHuman() {
      const messageList = document.getElementById('message-list')
      messageList.style['padding-bottom'] = '60px'
      messageList.style['bottom'] = '0px'
      const chatFile = saveDivAsImage('message-list').then(dataUrl => {
        chatStore.storeBotChatImage(dataUrl)
      })
      messageList.style['padding-bottom'] = '0px'
      messageList.style['bottom'] = '61px'
      this.$router.push({ name: 'ChatWithHuman' })
    },
    goToImagePreview(url) {
      this.$router.push({
        name: 'ImagePreview',
        params: { url },
        query: { url },
      })
    },
    goToKB(id, $event) {
      $event.stopImmediatePropagation()
      this.$router.push({
        name: 'KBScreen',
        params: { id },
      })
    },
    onActionClick(action, message, $event) {
      console.log('action" ', action)
      console.log('message" ', message)
      console.log('$event" ', $event)
      let uri = action.actUri
      let resource = action.fromResource
      let commId = action.commId
      let messageId = action.messageId
      let messagebody = action.messageBody
      let uriData
      uri = decodeURI(uri)
      if (!~uri.indexOf('://')) {
        return
      }
      console.log('------>>> uri is ', uri)

      if (uri.startsWith('http')) {
        window.open(uri, '_blank')
      } else if (uri.startsWith('bt-msg://')) {
        if (uri.substr(9) && decodeURI(uri.substr(9))) {
          chatService.sendMessage(uri.substr(9))
        }
      } else if (uri.startsWith('bt-bot://')) {
        try {
          uriData = chatService.parseUri(uri)
          console.log('uriData: ', uriData)
          if (uriData.authority) {
            chatService.sendMessage(
              JSON.stringify({
                message: encodeURI(uri),
                secret: CryptoJS.MD5('data').toString(),
                channel: 'chat',
                context: {
                  source: messageId,
                },
              }),
            )
          }
        } catch (error) {
          console.log('error: ', error)
        }
      } else if (uri.startsWith('bt-data://')) {
        uriData = chatService.parseUri(uri)
        if (uriData.authority) {
          chatService.sendMessage(
            JSON.stringify({
              message: encodeURI(uri),
              secret: CryptoJS.MD5('data').toString(),
              channel: 'chat',
              context: {
                source: messageId,
              },
            }),
          )
        }
      } else if (uri.startsWith('bt-action://')) {
        uriData = chatService.parseUri(uri)
        if (uriData.authority) {
          // var commApi = new com.goboomtown.sdk.swagger.api.CommApi($http, $httpParamSerializer, $injector),
          // chatButtonCallback = commApi.chatButtonCallback({
          //     data: JSON.stringify(uriData.queryDict),
          //     fromResource: fromResource,
          //     commId: commId,
          //     messageId: messageId,
          //     messageBody: messageBody
          // });
          // chatButtonCallback.success(function (callbackResponse) {
          //     if (callbackResponse.success) {
          //         if (callbackResponse.uri) {
          //             me.handleActionUri(callbackResponse.uri, fromResource, commId, messageId,messageBody);
          //         }
          //     } else {
          //     }
          // }).error(function (callbackResponse) {
          // });
        }
      }
    },
    onRatingClick(action, message, $event) {
      this.onActionClick(action, message, $event)
    },
  },
  mounted() {
    window.setTimeout(() => {
      this.$refs.message && this.$refs.message.scrollIntoView()
    }, 150)
  },
  computed: {
    parsedMessage() {
      if (this.message.resource === 'ovation-bot') {
        return parseMarkup(this.message.message)
      } else {
        return this.message.message
      }
    },
    fromUser() {
      if (this.message.resource.match('guest-')) {
        return true
      }

      if (this.message.resource === 'ovation-bot') {
        return false
      }

      if (
        this.message.resource.match(this.memberStore.member.members_users_id)
      ) {
        return true
      } else {
        return false
      }
    },
    showFromName() {
      if (
        this.previousMessage &&
        this.message.resource == this.previousMessage.resource &&
        !this.previousMessage.hideMessage
      ) {
        let diffInMilliseconds = Math.abs(
          new Date(this.message.timestamp) -
            new Date(this.previousMessage.timestamp),
        )
        let diffInSeconds = diffInMilliseconds / 1000
        if (diffInSeconds < 120) {
          return false
        } else {
          return true
        }
      } else {
        return true
      }
    },
    iconClasses() {
      let baseClasses = 'file-icon bt-icon bt-icon-file'
      let specificIconClass =
        ' bt-icon-file-' + this.message.attachmentName.split('.')[1]
      if (specificIconClass) baseClasses += specificIconClass
      return baseClasses
    },
    fromDisplayName() {
      if (this.message.name?.match('guest-')) {
        return 'Guest'
      } else {
        return this.message.name
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.message {
  padding-right: 10px;
  padding-left: 36px;
  position: relative;
  margin-bottom: 4px;
}

/* right-aligned messges that come immediately after left-aligned messages */
.align-left + .align-right {
  margin-top: 10px;
}
/* left-aligned messges that come immediately after right-aligned messages */
.align-right + .align-left {
  margin-top: 10px;
}

.message.align-right {
  padding-left: 0px;
}

.avatar-wrapper {
  overflow: hidden;
  position: absolute;
  top: 28px;
  left: 0px;
  width: 32px;
  height: 32px;
}

.avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 1px solid #fff;
  background: #fff;
}

.message.show-avatar {
  padding-left: 36px;
}

.from-name {
  font-size: 11px;
  font-weight: bold;
  color: #97afc1;
  margin: 6px 0px;
}

.timestamp {
  font-weight: normal;
}

.align-right {
  text-align: right;
}

.message-text {
  background-color: #f3f8f8;
  border-radius: 6px;
  margin: 2px 0px 6px 0px;
  padding: 13px 15px;
  position: relative;
  word-break: break-word;
  font-size: 14px;
  line-height: 18px;
  min-width: auto;
  max-width: 82%;
  display: inline-block;
  color: #4f4f4f;
  text-align: left;
}

.align-right {
  .message-text {
    background-color: #dbf2f9;
  }
}

.image-wrapper {
  cursor: pointer;
}

.file-attachment {
  max-width: 65%;
  min-width: 30%;
  margin: 4px 0px;
  padding: 3px 0px 3px 0px;
  border-radius: 14px;
  background-color: white;
  display: inline-block;
}

.file-attachment-link {
  text-decoration: none;
}

.file-attachment-link:hover {
  .file-wrapper {
    transition: 250ms all linear;
    border: 1px solid #b6b6b6;
  }
}

.file-wrapper {
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  padding: 8px 12px;
  text-align: left;
}

.file-icon {
  font-size: 32px;
  margin-right: 10px;
  vertical-align: middle;
  color: #595959;
  margin-bottom: 3px;
}

.file-name {
  font-size: 13px;
  color: #4f4f4f;
  font-weight: 500;
}

.file-size {
  font-size: 12px;
  color: #acacac;
}

.bt-icon-file-pdf {
  color: #e41c13;
}

.bt-icon-file-xls {
  color: #089a53;
}

.bt-icon-file-doc {
  color: #1567d6;
}

.transfer-message {
  font-size: 12px;
  color: #4f4f4f;
  text-align: center;
  margin-top: 10px;
}

.message-text {
  &:deep(p) {
    margin-block-start: 0;
    margin-block-end: 0;
  }
}
</style>
